import React from "react"
import PageTemplate from "../components/pageTemplate"
import { Alert } from "reactstrap"

let Bereitschaftsdienste = () => (
  <PageTemplate title="Bereitschaftsdienst">
    <h2>Apotheke</h2>
    <p>
      <strong>
        Liste aller Bereitschaftsdienste auf Fuxapotheke St. Marein/Graz:
      </strong>
      <br />
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.fuxapotheke.at/bereitschaftsdienst/"
      >
        https://www.fuxapotheke.at/bereitschaftsdienst/
      </a>
    </p>
    <h2>Ärzte</h2>
    <p>
      <strong>Dienstsprengel:</strong> Nestelbach - St. Marein - Laßnitzhöhe -
      Pachern - Kainbach
    </p>
    <p>Visitendienst 19:00-7:00h und am Wochenende</p>
    <p>Vorbehaltlich kurzfristiger Dienständerungen.</p>
    <p>
      <b>
        Bei Nichterreichbarkeit Rettungsnotruf 144 oder jeweilige Ortsvorwahl +
        141
      </b>
    </p>
    <Alert color="danger">
      Beratungshotline zum Coronavirus unter 1450 ! Weitere Informationen
      unter{" "}
      <a target="_blank" rel="noreferrer" href="https://www.1450.at">
        www.1450.at
      </a>
    </Alert>
  </PageTemplate>
)

export default Bereitschaftsdienste
